import React from "react";
import merge from "deepmerge";
import moment from "moment";

class RenderButtons extends React.Component {
  constructor(props) {
    super(props);
    this.pageTitle = props.content.pageTitle;
    this.core = this.props.core;
    this.appId = this.props.appId;
    this.pageId = this.props.pageId;
    this.fileData = this.props.currentRow;
    this.appNavigationDiv = "navigation_" + this.appId;
    this.userProfile = this.props.core
      ? this.props.core.make("oxzion/profile").get().key
      : undefined;
    this.baseUrl = this.core.config('wrapper.url');
    this.userData = this.core.getUser();
  }

  createTiles = () => {
    let adminItems = [];
    var rowData = this.fileData;
    this.props.content.buttonList.map((currentValue, index) => {
      var showButton;
      if (currentValue.rule) {
        const profile = this.userProfile; //for eval
        var string = this.replaceParams(currentValue.rule, rowData);
        var _moment = moment;
        string = string.replace(/moment/g, '_moment');
        showButton = eval(string);
      } else {
        showButton = true;
      }
      var copyPageContent = [];
      var that = this;
      if (currentValue.details && currentValue.details.length > 0) {
        currentValue.details.every(async (item, index) => {
          if (item.params && item.params.page_id) {
            copyPageContent.pageId = item.params.page_id;
          } else if (item.pageId) {
            copyPageContent.pageId = item.page_id;
          } else {
            var pageContentObj = item;
            pageContentObj = that.replaceParams(item, rowData);
            copyPageContent.push(pageContentObj);
          }
        });
      }
      var pageDetails = { title: currentValue.name, pageContent: copyPageContent, pageId: currentValue.pageId, icon: currentValue.icon, parentPage: this.pageId }
      if (showButton) {
        const isExportPdf = currentValue?.details?.find((detail) => detail?.type === 'exportPdf');
        const isDownload = currentValue?.details?.find(
          (detail) => detail?.type === "download"
        );
        adminItems.push(
          <div
            key={index}
            className="moduleBtn mt-3 mr-4"
            onClick={() => {
              if (isDownload) return this.downloadFile(pageDetails);
              let p_ev = new CustomEvent("addPage", {
                detail: pageDetails,
                bubbles: true
              });
              document.getElementById(this.appNavigationDiv).dispatchEvent(isExportPdf ? new CustomEvent("exportPdf") : p_ev);
            }}
          >
            <div className="block">
              {currentValue.icon ? (
                <i className={currentValue.icon} title={currentValue.name}></i>
              ) : (
                currentValue.name
              )}
            </div>
            {currentValue.icon ? (
              <div className="titles">{currentValue.name}</div>
            ) : null}
          </div>
        );
      }
    });
    return adminItems;
  };
  downloadFile({ pageContent }) {
    //const url = `${this.baseUrl}app/${this.state.appId}/${pageContent[0]?.url.replace("{{uuid}}",this.userProfile?.uuid)}`
    const url = `${this.baseUrl}${pageContent[0]?.url.replace("{{uuid}}", this.userProfile?.uuid)}`
    fetch(url, { method: 'post', body: JSON.stringify(this.userProfile), headers: { "Authorization": `Bearer ${this.userData['jwt']}` } }).
      then(v => v.blob()).then((response) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(response);
        if (pageContent[0]?.fileName) {
          a.download = pageContent[0]?.fileName;
        }
        a.target = "_blank";
        a.click();
      })
  }
  replaceParams(route) {
    var finalParams = merge(this.fileData ? this.fileData : {}, {
      current_date: moment().format("YYYY-MM-DD"),
      appId: this.appId
    });
    if (typeof route == "object") {
      var final_route = JSON.parse(JSON.stringify(route));
      Object.keys(route).map((item) => {
        if (/\{\{.*?\}\}/g.test(route[item])) {
          if (finalParams[item]) {
            final_route[item] = finalParams[item];
          } else {
            if (item == "appId") {
              final_route[item] = this.appId;
            } else {
              final_route[item] = route[item];
            }
          }
        } else {
          final_route[item] = route[item];
        }
      });
      return final_route;
    } else {
      var regex = /\{\{.*?\}\}/g;
      let m;
      var matches = [];
      do {
        m = regex.exec(route)
        if (m) {
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }
          // The result can be accessed through the `m`-variable.
          matches.push(m);
        }
      } while (m);
      matches.forEach((match, groupIndex) => {
        var param = match[0].replace("{{", "");
        param = param.replace("}}", "");
        if (finalParams[param] != undefined) {
          route = route.replace(
            match[0],
            finalParams[param]
          );
        } else {
          route = route.replace(
            match[0],
            null
          );
        }
      });
      return route;
    }
  }
  updateActionHandler(details, rowData) {
    var that = this;
    return new Promise((resolve) => {
      var queryRoute = that.replaceParams(details.params.url, rowData);
      that.updateCall(queryRoute, rowData).then((response) => {
        that.setState({
          showLoader: false
        });
        resolve(response);
      });
    });
  }
  render() {
    return (<div className=" k-header k-header-customtitle ">
      <div className="mb-5">
        <div className="appButtons">{this.createTiles()}
        </div>
      </div>
      {
        this.pageTitle && (<div className="row ml-3 mr-4"
          style={{
          "fontSize": "18px",
          "border": "0.5px solid #8f9bb3",
          "backgroundColor": "#F8F9FA",
          "height": "65px"
        }}>
          <h6
            style={{
            "color": "#8f9bb3",
            "alignSelf": "center",
            "marginRight" : "auto",
            "marginLeft" : "auto",
          }}>{this.pageTitle}</h6>
        </div>)
      }
    </div>);
  }
}

export default RenderButtons;
